<div layout="column">

	<p
		*ngIf="to.floatLabel === 'paragraph'"
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<mat-form-field
		[floatLabel]="to.floatLabel || 'auto'"
		[hideRequiredMarker]="true"
		override-mat-form-field-outline-thick
		(click)="datePicker.open()"
	>

		<mat-label
			*ngIf="to.floatLabel !== 'paragraph' && to.label && to.hideLabel !== true"
		>
			<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
			{{ to.label }}
			<span
				*ngIf="to.required"
				theme-palette-hue-fg="warn"
			>
				*
			</span>
		</mat-label>

		<input
			matInput
			(ngModelChange)="dataChanged()"
			[matDatepicker]="datePicker"
			[formControl]="datePickerFormControl"
			autocomplete="off"
			readonly
		>
		<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
		<mat-datepicker #datePicker></mat-datepicker>

		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>

		<div
			*ngIf="to.required"
			class="mat-form-field-subscript-wrapper fixed-required"
			theme-palette-hue-fg="warn"
		>
			{{'Form.ValidationMessage.required' | transloco}}
		</div>

	</mat-form-field>

</div>
