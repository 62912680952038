import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { NFormAPIJsonCustomFormDataContentComboboxOptions } from 'src/app/api-services/portal/nform-nonuser.interface';
import { NFormObjectFormlyFieldConfig } from '../../nform-nonuser.interface';

@Component({
	selector: 'nform-object-combobox',
	templateUrl: './combobox.component.html',
	styleUrls: ['./combobox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NFormComboboxComponent
	extends FieldType<NFormObjectFormlyFieldConfig>
	implements OnInit
{

	static nFormObjectName = 'nform-combobox';

	listOptions: NFormAPIJsonCustomFormDataContentComboboxOptions = [];

	_formControl: UntypedFormControl;

	ngOnInit(){
		this._formControl = this.formControl as FormControl<any>;
		this.listOptions = this.field.data.custom_form_data_content.combobox_options;
	}

}
