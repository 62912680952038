<div
	class="nform-picture-img-grid-dialog-container"
	layout="column"
	layout-fill
>

	<nform-picture-img-grid
		[formlyField]="data.formlyField"
		layout="column"
		layout-align="center center"
		flex
	></nform-picture-img-grid>

	<mat-toolbar
		layout="row"
		layout-align="center center"
		flex="none"
		class="picture-actions"
		color="primary"
	>
		<button
			*ngIf="!noGrid"
			mat-icon-button
			layout="column"
			layout-align="center"
			flex="none"
			(click)="data.formlyField.reset()"
		>
			<mat-icon>replay</mat-icon>
		</button>
		<button
			mat-icon-button
			layout="column"
			layout-align="center"
			flex="none"
			(click)="close()"
		>
			<mat-icon>check_circle</mat-icon>
		</button>
	</mat-toolbar>

</div>
