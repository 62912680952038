<div
	layout="column"
	[ngClass]="{
		'ng-touched': formControl.touched,
		'ng-invalid': formControl.invalid,
		'ng-untouched': formControl.untouched
	}"
	position-relative
>
	<p
		class="mat-body"
	>
		<span *ngIf="field.data.enumarated">{{field.data.enumarated}}.</span>
		{{ to.label }}
		<span
			*ngIf="to.required"
			theme-palette-hue-fg="warn"
		>
			*
		</span>
	</p>

	<div
		layout="row"
		layout-align-xs="center"
		layout-align-sm="center"
		class="buttons"
	>

		<button
			flex="none"
			layout-column
			layout-align="center center"
			mat-fab
			class="btn btn-{{eNFormRatingimageValues.green}}"
			[ngClass]="{
				active: this.formControl.value === eNFormRatingimageValues.green
			}"
			(click)="setValue(eNFormRatingimageValues.green)"
			color="default"
			[disabled]="formState.disabled"
		>
			<mat-icon svgIcon="sentiment_satisfied"></mat-icon>
		</button>

		<button
			*ngIf="field.data.custom_form_data_content.yellow === '1' "
			flex="none"
			layout-column
			layout-align="center center"
			mat-fab
			class="btn btn-{{eNFormRatingimageValues.yellow}}"
			[ngClass]="{
				active: this.formControl.value === eNFormRatingimageValues.yellow
			}"
			(click)="setValue(eNFormRatingimageValues.yellow)"
			color="default"
			[disabled]="formState.disabled"
		>
			<mat-icon svgIcon="sentiment_neutral"></mat-icon>
		</button>

		<button
			flex="none"
			layout-column
			layout-align="center center"
			mat-fab
			class="btn btn-{{eNFormRatingimageValues.red}}"
			[ngClass]="{
				active: this.formControl.value === eNFormRatingimageValues.red
			}"
			(click)="setValue(eNFormRatingimageValues.red)"
			color="default"
			[disabled]="formState.disabled"
		>
			<mat-icon svgIcon="sentiment_dissatisfied"></mat-icon>
		</button>

		<button
			*ngIf="field.data.required === 0 "
			flex="none"
			layout-column
			layout-align="center center"
			mat-fab
			class="btn btn-{{eNFormRatingimageValues.na}}"
			[ngClass]="{
				active: this.formControl.value === eNFormRatingimageValues.na
			}"
			(click)="setValue(eNFormRatingimageValues.na)"
			color="default"
			[disabled]="formState.disabled"
		>
			<mat-icon svgIcon="block"></mat-icon>
		</button>

	</div>

	<div class="mat-mdc-form-field-subscript-wrapper">
		<mat-error>
			<formly-validation-message [field]="field"></formly-validation-message>
		</mat-error>
	</div>

	<div
		*ngIf="to.required"
		class="mat-mdc-form-field-subscript-wrapper fixed-required"
		theme-palette-hue-fg="warn"
	>
		{{'Form.ValidationMessage.required' | transloco}}
	</div>

</div>
